import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center flex-grow-1"},[_c('cardknox-token',{attrs:{"expiry":_vm.cardknoxExp,"x-exp-element":"input[name=xExp]","cvv-element":"#cardknox-cvv","card-element":"#cardknox-card","i-fields-key":_vm.paymentMethod.iFieldsKey,"iframe-props":_vm.iframeProps,"input-style":_vm.inputStyle},on:{"token":function($event){return _vm.$emit('token', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var submit = ref.submit;
var loading = ref.loading;
return [_c('div',{staticClass:"d-flex justify-center flex-grow-1 flex-gap-8 flex-column"},[_c('label',{attrs:{"for":"xCardNum"}},[_vm._v(_vm._s(_vm.t('Card number')))]),_c('div',{attrs:{"id":"cardknox-card"}}),_c('label',{attrs:{"for":"xCVV"}},[_vm._v(_vm._s(_vm.t('CVV')))]),_c('div',{attrs:{"id":"cardknox-cvv"}}),_c('label',{attrs:{"for":"xExp"}},[_vm._v(_vm._s(_vm.t('Expiry')))]),_c(VTextField,{directives:[{name:"maska",rawName:"v-maska"}],staticStyle:{"width":"300px"},attrs:{"label":_vm.t('MM/YY'),"outlined":"","hide-details":"auto","name":"xExp","data-maska":"##/##"},model:{value:(_vm.cardknoxExp),callback:function ($$v) {_vm.cardknoxExp=$$v},expression:"cardknoxExp"}}),_c('div',_vm._l((Object.values(errors || {})),function(error,index){return _c('span',{key:("cardknox-error-" + index),staticClass:"mt-4 error--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)],1),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","loading":loading},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.t('Proceed'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }