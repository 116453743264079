import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","small":"","color":"warning"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.t('Void'))+" ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog)?_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.t('Void order'))+": "+_vm._s(_vm.value.number))])]),_c(VDivider),_c(VCardText,{staticClass:"d-flex flex-column mt-4 flex-gap-8"},[_c('switch-field',{attrs:{"label":_vm.t('Notify user')},model:{value:(_vm.voidOptions.notify),callback:function ($$v) {_vm.$set(_vm.voidOptions, "notify", $$v)},expression:"voidOptions.notify"}}),_c('switch-field',{attrs:{"label":_vm.t('Restock items')},model:{value:(_vm.voidOptions.restock),callback:function ($$v) {_vm.$set(_vm.voidOptions, "restock", $$v)},expression:"voidOptions.restock"}}),_c('switch-field',{attrs:{"label":_vm.t('Void offline')},model:{value:(_vm.voidOptions.offline),callback:function ($$v) {_vm.$set(_vm.voidOptions, "offline", $$v)},expression:"voidOptions.offline"}}),_c('span',{staticClass:"mt-8"},[_vm._v(_vm._s(_vm.t('Are you sure you want to void order?')))])],1),_c(VDivider),_c(VCardActions,{staticClass:"justify-space-between ma-2"},[_c(VBtn,{attrs:{"depressed":"","color":"secondary","text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" "+_vm._s(_vm.t('No'))+" ")]),_c(VBtn,{attrs:{"depressed":"","color":"primary","loading":_vm.loading},on:{"click":_vm.voidHandler}},[_vm._v(" "+_vm._s(_vm.t('Yes'))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }