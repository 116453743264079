import { ref, computed } from '@vue/composition-api'
import { paginateCollection } from '../libs/tables/grid'

export const usePagination = <T>(perPage = 5, page = 1) => {
  const list = ref<T[]>()
  const currentPage = ref<number>(page)
  const maxPages = ref<number>(0)
  const fromIndex = ref<number>(0)
  const toIndex = ref<number>(0)

  const currentList = computed((): T[] => (list.value || []).slice(fromIndex.value, toIndex.value))

  const setCollection = (value: T[]): void => {
    list.value = value
    setPage(1)
  }

  const setPage = (page: number): void => {
    const {
      current_page: current,
      last_page: last,
      from_item_index: from,
      to_item_index: to,
    } = paginateCollection((list.value || []).length, page, perPage)

    currentPage.value = current
    maxPages.value = last
    fromIndex.value = from
    toIndex.value = to
  }

  return {
    currentList,
    page: computed(() => currentPage.value),
    max: computed(() => maxPages.value),
    setCollection,
    setPage,
  }
}