import { DynamicAttributeType } from '@futureecom/futureecom-js/dist/services/setting-service'
import type { RuleDefinitions } from '@/types/validator'
import type {
    DynamicAttributeDefinitions,
    DynamicNumericAttribute,
    DynamicTextAttribute,
    DynamicStringAttribute,
    DynamicSelectAttribute,
    DynamicLinkedAttribute,
    MinifiedDynamicAttribute,
} from '@futureecom/futureecom-js/dist/services/setting-service'

export const getDefinitionsValidations = (val: DynamicAttributeDefinitions[]): RuleDefinitions => {
    return val.reduce((prev, { attributes }) => {
        const validations = attributes.reduce((prevAttrs, attribute) => {
            return { ...prevAttrs, [`${attribute.code}`]: attribute.validation }
        }, {})
        return { ...prev, ...validations }
    }, {})
}

export const typeGuard = <T extends MinifiedDynamicAttribute>(
    definition: MinifiedDynamicAttribute,
    type: DynamicAttributeType
): definition is T => {
    return definition.type === type
}

export const booleanConfigurationGuard = (val: MinifiedDynamicAttribute): val is DynamicNumericAttribute => {
    return typeGuard(val, DynamicAttributeType.BOOLEAN)
}

export const stringConfigurationGuard = (val: MinifiedDynamicAttribute): val is DynamicStringAttribute => {
    return typeGuard(val, DynamicAttributeType.STRING)
}

export const textConfigurationGuard = (val: MinifiedDynamicAttribute): val is DynamicTextAttribute => {
    return typeGuard(val, DynamicAttributeType.TEXT)
}

export const numericConfigurationGuard = (val: MinifiedDynamicAttribute): val is DynamicNumericAttribute => {
    return [DynamicAttributeType.NUMERIC, DynamicAttributeType.MONEY].some((type) => typeGuard(val, type))
}

export const selectConfigurationGuard = (val: MinifiedDynamicAttribute): val is DynamicSelectAttribute => {
    return [
        DynamicAttributeType.COMBOBOX,
        DynamicAttributeType.SELECT,
        DynamicAttributeType.SELECT_MULTIPLE,
        DynamicAttributeType.CHECKBOX,
    ].some((type) => typeGuard(val, type))
}

export const linkedConfigurationGuard = (val: MinifiedDynamicAttribute): val is DynamicLinkedAttribute => {
    return typeGuard(val, DynamicAttributeType.LINKED)
}