import { WILDCARD, extendWildcardRules } from '@/composable/validator/wildcard'
import { computed, ref } from '@vue/composition-api'
import {
  contextual,
  isRuleContextual,
  isRuleParametrized,
  isRuleStandalone,
  is_empty,
  parametrized,
  standalone
} from '@/composable/validator/rules'
import { getCustomMessageFallback, splitRuleParams } from '@/composable/validator/messages'
import dotProp from 'dot-prop'
import type { Rule, RuleContext, RuleDefinitions, RuleNameContextual, Rules, ValidationErrors } from '@/types/validator'

export const useValidator = <T extends object>(initValue?: Partial<T>) => {
  const forcedValidation = ref(false)
  const previousModel = ref<Partial<T>>(initValue || {})
  const currentModel = ref<Partial<T>>({})
  const validationErrors = ref<ValidationErrors>({})

  const setModel = (val: T) => (previousModel.value = val)
  const setErrors = (val: ValidationErrors) => (validationErrors.value = val)
  const setErrorsFromAPI = (val: Record<string, string[]>) => {
    const errors = Object.entries(val).reduce((prev, [key, value]) => {
      return { ...prev, [key]: value[0] }
    }, {})
    setErrors(errors)
  }

  const errors = computed((): Record<string, string> => {
    return Object.entries(validationErrors.value).reduce((prev, [key, value]) => {
      return value ? { ...prev, [key]: value } : prev
    }, {})
  })

  const isValid = computed(() => !Object.keys(errors.value).length)

  const getValidationErrorMessages = (field: string, rules: Rules): string => {
    const valCurrent = dotProp.get(currentModel.value, field, undefined)
    const valPrev = dotProp.get(previousModel.value, field, undefined)

    const hasChanged = JSON.stringify(valPrev) !== JSON.stringify(valCurrent)
    const canValidate =
      forcedValidation.value ||
      valPrev === undefined ||
      valCurrent === undefined ||
      (!forcedValidation.value && hasChanged)

    if (!rules.length && valCurrent === undefined) {
      return ''
    }

    const context: RuleContext = { model: valCurrent || '', currentKey: field }
    return canValidate ? validateRules(valCurrent, rules, context) : validationErrors.value[field]
  }

  const validateExistingErrorsChanges = (): ValidationErrors => {
    return Object.entries(validationErrors.value).reduce((prev, [field, message]) => {
      const valCurrent = dotProp.get(currentModel.value, field)
      const valPrev = dotProp.get(previousModel.value, field)

      const hasChanged = JSON.stringify(valPrev) !== JSON.stringify(valCurrent)
      return hasChanged ? { ...prev } : { ...prev, [field]: message }
    }, {})
  }

  const getSimpleValidationErrors = (rules: RuleDefinitions): ValidationErrors => {
    return Object.keys(rules).reduce((prev, ruleKey: string) => {
      if (ruleKey.includes(WILDCARD)) {
        return { ...prev }
      }

      const message = getValidationErrorMessages(ruleKey, rules[ruleKey])
      return { ...prev, [ruleKey]: message }
    }, {})
  }

  const getNestedValidationErrors = (rules: RuleDefinitions): ValidationErrors => {
    const wildcardRules = Object.entries(rules).reduce((prev: RuleDefinitions, [key, value]) => {
      return !key.includes(WILDCARD) ? prev : { ...prev, [key]: value }
    }, {})

    const extendedRules = extendWildcardRules(currentModel.value, wildcardRules)

    return Object.entries(extendedRules).reduce((prev, [path, rule]) => {
      const message = getValidationErrorMessages(path, rule)
      return { ...prev, [path]: message }
    }, {})
  }

  const validateRules = (value: unknown, rules: Rules, ctx?: RuleContext): string => {
    if (is_empty(value) && rules.includes('nullable')) {
      return ''
    }

    return rules.reduce((errorMessage: string, rule: Rule) => {
      if (errorMessage) {
        return errorMessage
      }

      if (typeof rule === 'function') {
        return rule(value)
      }

      const unifiedRule = typeof rule === 'string' ? { rule, message: '' } : rule
      const { ruleName, params } = splitRuleParams(unifiedRule.rule)

      if (isRuleStandalone(ruleName)) {
        return getCustomMessageFallback(standalone[ruleName](value), unifiedRule.message)
      }

      if (isRuleParametrized(ruleName)) {
        const ruleFn = parametrized[ruleName] as (val: any, param: any) => string;
        return getCustomMessageFallback(ruleFn(value, params), unifiedRule.message);
      }

      if (!isRuleContextual(ruleName)) {
        return errorMessage
      }

      if (!ctx) {
        return errorMessage
      }

      if ((['same', 'required_with'] as RuleNameContextual[]).includes(ruleName)) {
        const message = contextual[ruleName](value, { ...ctx, params: `${params}` });
        return getCustomMessageFallback(message, unifiedRule.message);
      }

      return errorMessage
    }, '')
  }

  const validate = (value: Partial<T>, rules: RuleDefinitions, force: boolean = false) => {
    currentModel.value = structuredClone(value)
    forcedValidation.value = force
    validationErrors.value = forcedValidation.value ? {} : validationErrors.value

    const currentErrors = validateExistingErrorsChanges()
    const simpleValueErrors = getSimpleValidationErrors(rules)
    const nestedValueErrors = getNestedValidationErrors(rules)

    validationErrors.value = { ...currentErrors, ...simpleValueErrors, ...nestedValueErrors }

    previousModel.value = structuredClone(value)
    currentModel.value = undefined
    forcedValidation.value = false
  }

  return {
    isValid,
    errors,
    validate,
    setErrorsFromAPI,
    setErrors,
    setModel,
    validateRules
  }
}
