import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('accept-blue-token',{attrs:{"source-key":_vm.paymentMethod.sourceKey,"styles":{
    labelType: 'floating',
    container: "display: flex; flex-direction: column; gap: 32px; padding: 16px 0;",
    card: _vm.inputStyle,
    cvv2: _vm.inputStyle,
    expiryContainer: _vm.inputStyle,
    floatingLabelsPlaceholder: 'color: #b2b2b2',
  },"test-mode":_vm.paymentMethod.testMode},on:{"token":function($event){return _vm.$emit('token', $event)},"error":_vm.addErrorToast},scopedSlots:_vm._u([{key:"after",fn:function(ref){
  var submit = ref.submit;
  var loading = ref.loading;
return [_c('div',{staticClass:"d-flex justify-end mt-4"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","loading":loading},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.t('Proceed'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }