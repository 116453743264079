import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expandable-content',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('selectable-resource-header',{attrs:{"errors":_vm.chipErrors,"title":_vm.t('Featured Products'),"items":_vm.value.featured_products}})]},proxy:true},{key:"content",fn:function(){return [_c('selectable-products',{staticClass:"ma-4",attrs:{"grid-options":_vm.gridOptions,"show-chips":"","multiple":"","errors":_vm.chipErrors},scopedSlots:_vm._u([{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"depressed":"","plain":"","text":"","small":"","to":{ name: 'product-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(_vm.canAdd(item).value)?_c(VSimpleCheckbox,{attrs:{"ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}}):_vm._e()]}}]),model:{value:(_vm.featuredProducts),callback:function ($$v) {_vm.featuredProducts=$$v},expression:"featuredProducts"}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }