import type { Framework } from 'vuetify'

const makeUrl = (path: string) => `${window.location.origin}/img/cards/${path}`

export const styling = (vuetify: Framework) => {
  return {
    'input': {
      'background': '#fff',
      'border': '1px solid',
      'border-radius': '4px',
      'border-color': '#bbb3b9 #c7c1c6 #c7c1c6',
      'box-sizing': 'border-box',
      'font-family': 'sans-serif',
      'font-size': '16px',
      'line-height': '1',
      'margin': '0 .5em 0 0',
      'max-width': '100%',
      'outline': '0',
      'padding': '0.5278em',
      'vertical-align': 'baseline',
      'height': '50px',
    },
    '#heartland-field': {
      'font-family': 'sans-serif',
      'box-sizing': 'border-box',
      'display': 'block',
      'padding': '6px 12px',
      'font-size': '14px',
      'line-height': '1.42857143',
      'color': '#555',
      'background-color': '#fff',
      'border': '1px solid #ccc',
      'border-radius': '4px',
      '-webkit-box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
      'box-shadow': 'inset 0 1px 1px rgba(0,0,0,.075)',
      '-webkit-transition': 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      '-o-transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      'transition': 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    },
    '#heartland-frame-submit': {
      'display': 'flex',
      'align-items': 'center',
      'justify-content': 'center',
      'height': '36px',
    },
    '#heartland-field[name=submit]': {
      'height': '36px',
      'min-width': '64px',
      'padding': '0 16px',
      'color': '#fff',
      'background-color': vuetify.theme.currentTheme.primary,
      'font-size': '0.875rem',
      'outline': '0',
      'border': 'none',
      'border-color': vuetify.theme.currentTheme.primary,
      'border-radius': '4px',
      'text-decoration': 'none',
      'transition-duration': '0.28s',
      'cursor': 'pointer',
      'text-transform': 'uppercase',
      'line-height': '24px',
      'font-weight': '500',
      'letter-spacing': '1.25px',
      'font-family':
        'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },
    '#heartland-field[name=submit]:hover': {
      ' opacity': '0.9',
    },
    '#heartland-field-wrapper': {
      'display': 'flex',
      'justify-content': 'end',
    },
    'input[type=submit]': {
      'box-sizing': 'border-box',
      'display': 'inline-block',
      'padding': '6px 12px',
      'margin-bottom': '0',
      'font-size': '14px',
      'font-weight': '400',
      'line-height': '1.42857143',
      'text-align': 'center',
      'white-space': 'nowrap',
      'vertical-align': 'middle',
      '-ms-touch-action': 'manipulation',
      'touch-action': 'manipulation',
      'cursor': 'pointer',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
      'background-image': 'none',
      'border': '1px solid transparent',
      'border-radius': '4px',
      'color': '#fff',
      'background-color': '#337ab7',
      'border-color': '#2e6da4',
    },
    '#heartland-field[placeholder]': {
      'letter-spacing': '3px',
    },
    '#heartland-field[name=cardCvv]': {
      'background': `transparent url(${makeUrl('code_grayscale.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
      'backdrop-filter': 'blur(10px)',
      'width': '100%',
    },
    'input#heartland-field[name=cardNumber]': {
      'background': `transparent url(${makeUrl('generic.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
      'width': '100%',
    },
    'input#heartland-field[name=cardExpiration]': {
      width: '100%',
    },
    '#heartland-field.valid.card-type-visa': {
      'background': `transparent url(${makeUrl('visa.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
    },
    '#heartland-field.valid.card-type-discover': {
      'background': `transparent url(${makeUrl('discover.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
    },
    '#heartland-field.valid.card-type-amex': {
      'background': `transparent url(${makeUrl('amex.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
    },
    '#heartland-field.valid.card-type-mastercard': {
      'background': `transparent url(${makeUrl('mastercard.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
    },
    '#heartland-field.valid.card-type-jcb': {
      'background': `transparent url(${makeUrl('jcb.svg')}) no-repeat 98%`,
      'background-size': '55px 35px',
    },
    'input#heartland-field[name=cardNumber]::-ms-clear': {
      display: 'none',
    },
  }
}
