import Big from 'big.js'
import type { Money } from '@futureecom/futureecom-js'
import { useCurrency } from '@/composable/repositories/useCurrency'

export const useMoney = () => {
  const { storeCurrency, storeLocale, getCurrencyDecimalPoint } = useCurrency()

  const fromMoneyAmount = (amount: string | number, currency?: string): string => {
    const value = Number(amount)
    const position = getCurrencyDecimalPoint(currency)
    const divider = Number(new Big(10).pow(position).toString())
    return new Big(value).div(divider).toString()
  }

  const toMoneyAmount = (amount: string | number, currency?: string): string => {
    const precisionPoint = Big(10).pow(getCurrencyDecimalPoint(currency))
    const sanitizedValue = Big(`${amount}`.replaceAll(',', '.'))
    return new Big(sanitizedValue).mul(precisionPoint).toString().split('.')[0]
  }

  const toMoney = (amount: string | number, currency?: string): Money => ({
    amount: toMoneyAmount(amount),
    currency: currency || storeCurrency.value,
  })

  const formatMoneyWithCurrency = (value: Money, multiplier = 1): string => {
    if (!value?.amount) {
      value.amount = 0
    }

    const totalAmount = new Big(value.amount).mul(multiplier).toString()

    try {
      const amount = fromMoneyAmount(totalAmount, value.currency)
      const intlOptions = { style: 'currency', currency: value.currency || storeCurrency.value }
      return new Intl.NumberFormat(storeLocale.value, intlOptions).format(parseFloat(`${amount}`))
    } catch (err: any) {
      console.error(err.message)
      const amount = fromMoneyAmount(totalAmount, 'USD')
      return new Intl.NumberFormat(storeLocale.value, { minimumFractionDigits: 2 }).format(parseFloat(`${amount}`))
    }
  }

  const subtract = (from: Money, value: Money): Money => {
    const fromAmount = new Big(from.amount.toString());
    const valueAmount = new Big(value.amount.toString());
    const result = fromAmount.sub(valueAmount);

    return {
      currency: from.currency || storeCurrency.value,
      amount: result.toString(),
    };
  }

  const add = (value: Money, addition: Money): Money => {
    const valueAmount = new Big(value.amount.toString());
    const additionAmount = new Big(addition.amount.toString());
    const result = valueAmount.add(additionAmount);

    return {
      currency: value.currency || storeCurrency.value,
      amount: result.toString(), 
    };
  };

  const multiply = (value: Money, multiplier = 1): Money => {
    const valueAmount = new Big(value.amount.toString());
    const result = valueAmount.mul(multiplier);

    return {
      currency: value.currency || storeCurrency.value,
      amount: result.toString(), 
    };
  };

  return {
    fromMoneyAmount,
    toMoneyAmount,
    toMoney,
    withStoreCurrency: formatMoneyWithCurrency,
    add,
    subtract,
    multiply,
  }
}