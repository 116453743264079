import Vue from 'vue';
export const state = Vue.observable({
  currentRoute: null,
  changeType: 'common',
  variantLoading: false,
  ddiUserData:null,
  userEmail: null
});

export const mutations = {
    detectRoute(data) {
    state.currentRoute = data;
  },
  setChangeType(data) {
    state.changeType = data;
  },
  setvariantLoading(data) {
    state.variantLoading = data;
  },
  setddiUserData(data) {
    state.ddiUserData = data;
  },
  setuserEmail(data) {
    state.userEmail = data;
  },
};