import type { PaymentMethodTypes } from '@/types/payment/methods'
import type { RuleDefinitions } from '@/types/validator'

export const providerRules: Record<string | PaymentMethodTypes, RuleDefinitions> = {
  braintree: {
    merchantId: ['required', 'string'],
    tokenizationKey: ['required', 'string'],
    publicKey: ['required', 'string'],
    privateKey: ['required', 'string'],
  },
  cardknox: {
    xKey: ['required', 'string'],
    iFieldsKey: ['required', 'string'],
  },
  paypal: {
    clientId: ['required', 'string'],
    secret: ['required', 'string'],
  },
  poynt_terminal: {
    business_id: ['required', 'string'],
    store_id: ['required', 'string'],
    application_id: ['required', 'string'],
  },
  poynt: {
    business_id: ['required', 'string'],
    store_id: ['required', 'string'],
    application_id: ['required', 'string'],
    private_key: ['required', 'string'],
  },
  stripe: {
    apiKey: ['required', 'string'],
    publishableKey: ['required', 'string'],
  },
  bank_transfer: {
    account_number: ['required', 'string'],
  },
  cardknox_pos: {
    xKey: ['required', 'string'],
    iFieldsKey: ['required', 'string'],
    deviceIpAddress: ['required', 'string'],
    deviceIpPort: ['required', 'string'],
  },
  converge: {
    merchantId: ['required', 'string'],
    username: ['required', 'string'],
    password: ['required', 'string'],
  },
  tranzila: {
    supplier: ['required', 'string'],
    terminal_password: ['required', 'string'],
    type: ['required', 'string'],
  },
  acceptblue: {
    apiPin: ['nullable', 'string'],
    apiSourceKey: ['nullable', 'string'],
    sourceKey: ['nullable', 'string'],
  },
}
