import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(_vm.loading.get || _vm.loading.customer)?_c('div',{staticClass:"d-flex justify-center my-4"},[_c(VProgressCircular,{attrs:{"size":"32","color":"primary","indeterminate":""}})],1):_c('scrollable-resource',{scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.item)?_c('resource-header',{attrs:{"back-to":{ name: 'quotes' },"header":("Quote for: " + (_vm.item.customer.firstname) + " " + (_vm.item.customer.lastname)),"subheader":_vm.item.id},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.item)?_c('div',{staticClass:"d-flex flex-gap-16"},[(_vm.canDelete)?_c('quote-action-delete',{attrs:{"value":_vm.item},on:{"onSuccess":_vm.redirectToGrid}}):_vm._e(),_c(VScrollYReverseTransition,{attrs:{"mode":"out-in"}},[(_vm.selectedPaymentMethod && !_vm.selectedPaymentMethod.provider.endsWith('Offline'))?_c('orderable-pay-now-dialog',{attrs:{"value":_vm.item,"checkout":_vm.checkoutData,"payment-method":_vm.selectedPaymentMethod,"diabled":!(_vm.isQuoteValid && _vm.isCheckoutValid)}}):_c('orderable-action-checkout',{attrs:{"value":_vm.item,"checkout":_vm.checkoutData,"is-valid":_vm.isQuoteValid && _vm.isCheckoutValid,"disabled":_vm.triedToCheckout && !(_vm.isQuoteValid && _vm.isCheckoutValid)},on:{"update:tried-to-checkout":_vm.tryToCheckout}})],1)],1):_vm._e()]},proxy:true}],null,false,2356382698)}):_vm._e(),_c(VDivider)]},proxy:true},{key:"content",fn:function(ref){
var availableHeight = ref.availableHeight;
return [(_vm.item && _vm.customer && !_vm.loading.get)?_c('div',{staticClass:"d-flex"},[_c('scrollable',{staticClass:"ma-0 pa-0 col-8",attrs:{"height":(availableHeight + "px")},scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VSlideYReverseTransition,{attrs:{"mode":"out-in"}},[(_vm.selectedPaymentMethod && !(_vm.isQuoteValid && _vm.isCheckoutValid))?_c('orderable-checkout-errors',{staticClass:"ma-0",attrs:{"show-errors":!_vm.isCheckoutValid},model:{value:(_vm.checkoutErrors),callback:function ($$v) {_vm.checkoutErrors=$$v},expression:"checkoutErrors"}}):_vm._e()],1)]},proxy:true},(_vm.customer)?{key:"scrollable",fn:function(){return [_c('orderable-error-preview-dialog',{staticClass:"mx-4",model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}),_c('orderable-sections',{attrs:{"height":(availableHeight + "px"),"errors":Object.assign({}, _vm.cartErrors, _vm.checkoutErrors),"checkout":_vm.checkoutData,"customer":_vm.customer},on:{"update:checkout":[function($event){_vm.checkoutData=$event},_vm.onCheckoutChange],"update:customer":[function($event){_vm.customer=$event},_vm.onOrderableChange],"input":_vm.onOrderableChange,"update:paymentMethod":_vm.onPaymentMethodChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})]},proxy:true}:null],null,true)}),_c(VDivider,{staticClass:"mx-0",attrs:{"vertical":""}}),_c('scrollable',{staticClass:"col-4 ma-0 pa-0",attrs:{"height":(availableHeight + "px")},scopedSlots:_vm._u([(_vm.item)?{key:"scrollable",fn:function(){return [_c('quote-status-actions',{attrs:{"readonly":!_vm.isQuoteEditable},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}),_c(VDivider),_c('orderable-preview-sections',{attrs:{"readonly":!_vm.isQuoteEditable},on:{"input":_vm.onOrderableChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})]},proxy:true}:null],null,true)})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }