import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({attrs:{"selected-items":_vm.selected,"isCustom":true},on:{"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'customer-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new customer'))+" ")]):_vm._e()]},proxy:true},{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.firstname)+" ")])]}},{key:"item.lastname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.lastname)+" ")])]}},{key:"item.TaxExemptionStatus",fn:function(ref){
var item = ref.item;
return [(item.TaxExemptionStatus === 'approved')?_c('span',{staticClass:"text-success"},[_vm._v(" Tax Exemption ")]):_vm._e()]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [(item.telephone)?_c('div',[_vm._v("+"+_vm._s(item.telephone.prefix)+" "+_vm._s(item.telephone.number))]):_vm._e()]}},{key:"item.groups.code",fn:function(ref){
var item = ref.item;
return _vm._l((item.groups),function(group,index){return _c('router-link',{key:((item.id) + "-group-" + index),staticClass:"d-flex flex-gap-32",attrs:{"to":{ name: 'customer-group-update', params: { id: group.id } }}},[_vm._v(" "+_vm._s(group.name)+" ")])})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'customer-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('customer-action-delete',{attrs:{"value":item,"icon":""},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}},(_vm.canDelete)?{key:"grid.footer.actions",fn:function(){return [(_vm.errors)?_c('customer-action-force-mass-delete-dialog',{attrs:{"items":_vm.selected},on:{"success":_vm.onMassDeleteSuccess,"error":_vm.onRequestError},model:{value:(_vm.errors),callback:function ($$v) {_vm.errors=$$v},expression:"errors"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-gap-8"},[(_vm.canDelete)?_c('customer-action-mass-delete',{attrs:{"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"success":_vm.onMassDeleteSuccess,"error":function($event){_vm.errors = $event}}}):_vm._e(),_c('button-action',{attrs:{"label":_vm.$t('Force delete'),"items":_vm.selected,"text":"","disabled":_vm.grid.loading || !_vm.selected.length},on:{"click":function($event){_vm.errors = { message: _vm.$t('Confirm force delete') }}}}),_c('resource-grid',{attrs:{"selectable-key":"id","options":_vm.options,"items":_vm.grid.data,"hide-default-footer":"","selected-items":_vm.selected,"show-select":_vm.canDelete,"isCustom":true},on:{"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCustomer ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.firstname)+" ")])]}},{key:"item.lastname",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCustomer ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.lastname)+" ")])]}},{key:"item.telephone",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',[_vm._v("+"+_vm._s(value.prefix)+" "+_vm._s(value.number))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.groups.code",fn:function(ref){
var item = ref.item;
return _vm._l((item.groups),function(group,index){return _c('div',{key:((item.id) + "-group-" + index),staticClass:"d-flex flex-gap-32"},[_c(_vm.canAccessCustomer ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-group-update', params: { id: group.id } }}},[_vm._v(" "+_vm._s(group.name)+" ")])],1)})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'customer-update', params: { id: item.id } }}})]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c(_vm.canAccessCustomer ? 'router-link' : 'span',{tag:"router-link",attrs:{"to":{ name: 'customer-orders', params: { id: item.id  } }}},[_vm._v(" "+_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at))+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(_vm.canDelete)?_c('customer-action-delete',{attrs:{"value":item,"icon":""},on:{"onSuccess":_vm.onDeleteSuccess}}):_vm._e()]}}],null,false,857269620),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c(VDivider),(!_vm.heightExceeded)?_c('div',{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-space-between"},[_c('div',{staticClass:"d-flex flex-gap-8"},[(_vm.canDelete)?_c('customer-action-mass-delete',{attrs:{"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"onSuccess":_vm.onMassDeleteSuccess,"onError":function($event){_vm.errors = $event}}}):_vm._e(),_c('button-action',{attrs:{"label":_vm.$t('Force delete'),"items":_vm.selected,"text":"","disabled":_vm.grid.loading || !_vm.selected.length},on:{"click":function($event){_vm.errors = { message: _vm.$t('Confirm force delete') }}}})],1),_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()],1)]},proxy:true}:null],null,true)},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Customers'), gridProps: { showSelect: _vm.canDelete } },false))}
var staticRenderFns = []

export { render, staticRenderFns }