import { WorkflowGuardAlias } from '@futureecom/futureecom-js/dist/services/setting-service'
import { computed, ref } from '@vue/composition-api'
import type { OrderActionGuardContext } from '@/types/sales/order/guards' 
import type { OrderGuards, Order } from '@futureecom/futureecom-js/dist/services/order-service'
import type { WorkflowProcess, WorkflowProcessState } from '@futureecom/futureecom-js/dist/services/setting-service'
import { useSettings } from '@/composable/repositories/useSettings'
import { useApi } from '@/composable/useApi'

const guards = ref<OrderGuards>({})
const actions: Record<string, OrderActionGuardContext> = {
  release: { action: 'release' },
  hold: { action: 'hold' },
  void: { action: 'cancel', guard: WorkflowGuardAlias.CAN_VOID_ORDER },
  refund: { action: 'void', guard: WorkflowGuardAlias.CAN_REFUND_ORDER },
  bill: { action: 'bill', guard: WorkflowGuardAlias.IS_PAID_ORDER, negative: true },
  fulfill: { action: 'fulfill', guard: WorkflowGuardAlias.CAN_FULFILL_ORDER },
}

export const useOrderActionGuards = () => {
  const { getSettings } = useSettings()

  const workflowsSettings = computed((): WorkflowProcess => {
    const defaultOrder = getSettings<string>('workflows.defaults.order').value || ''
    return getSettings<WorkflowProcess>(`workflows.list.${defaultOrder}`).value
  })

  const workflowGuards = computed(() => workflowsSettings.value.guards || {})
  const workflowTransitions = computed(() => workflowsSettings.value.transitions || {})

  const fetchGuards = (orderId: string) => {
    useApi()
      .orders.getOrderGuards(orderId)
      .then((val) => (guards.value = val))
      .catch(() => (guards.value = {}))
  }

  const checkGuard = (guard: WorkflowGuardAlias, negative: boolean) => {
    return computed(() => {
      const result = Boolean(guards.value?.[guard])
      return negative ? !result : result
    })
  }

  const canPerformTransition = (from: WorkflowProcessState, transition: string) => {
    const transitFrom = (workflowTransitions as any).value[transition]?.from
    if (!transitFrom.length || !transitFrom.includes(from)) {
      return false
    }

    const hasChecks = Object.values(workflowGuards.value).some((item) => item.on === transition)
    if (!hasChecks) {
      return true
    }

    const initActionsToCheck: string[] = []

    const actionsToCheck = Object.values(workflowGuards.value)
      .reduce(
        (prev, current) => (current.on === transition ? [...prev, ...(current.do || [])] : prev),
        initActionsToCheck
      )
      .filter((item) => item in guards.value)
      .filter((item) => item !== 'condition.check')

    if (!actionsToCheck.length) {
      return true
    }

    return actionsToCheck.some((item) => Boolean(guards.value[item]))
  }

  const canPerformAction = (order: Order, data: OrderActionGuardContext) => {
    return computed(() => {
      const { action, guard, negative } = data
      if (!order.status) {
        return false
      }

      const isNotGuarded = guard ? checkGuard(guard, Boolean(negative)).value : true
      const transitions: string[] = workflowsSettings.value?.transitions[action]?.from || []
      const canTransit = transitions.includes(order.status)

      return isNotGuarded && canTransit
    })
  }

  const availableTransitions = (order: Order) => {
    return Object.keys(workflowTransitions.value).filter((transitionKey) => {
      return canPerformTransition(order.status, transitionKey)
    })
  }

  const orderActionGuards = (order?: Order) => {
    return computed(() => {
      if (!order) {
        return {
          canResolve: false,
          canHold: false,
          canVoid: false,
          canRefund: false,
          canBill: false,
          canFulfill: false,
        }
      }

      const canResolve = canPerformAction(order, actions.release).value
      const canHold = canPerformAction(order, actions.hold).value
      const canVoid = canPerformAction(order, actions.void).value
      const canRefund = canPerformAction(order, actions.refund).value
      const canBill = canPerformAction(order, actions.bill).value
      const canFulfill = canPerformAction(order, actions.fulfill).value

      return {
        canResolve,
        canHold,
        canVoid,
        canRefund,
        canBill,
        canFulfill,
      }
    })
  }

  return {
    canPerformAction,
    canPerformTransition,
    orderActionGuards,
    fetchGuards,
    availableTransitions,
  }
}
