import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('converge-token',{staticClass:"d-flex align-center justify-center flex-grow-1",attrs:{"merchant-id":_vm.paymentMethod.merchantId,"test-mode":_vm.paymentMethod.testMode},on:{"token":function($event){return _vm.$emit('token', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
var loading = ref.loading;
var submit = ref.submit;
return [_c('div',{staticClass:"d-flex flex-gap-16 flex-column flex-grow-1"},[_c(VTextField,{directives:[{name:"maska",rawName:"v-maska"}],attrs:{"data-maska":"###################","outlined":"","label":_vm.t('Card number'),"append-icon":"mdi-credit-card","hide-details":"auto"},model:{value:(form.ssl_card_number),callback:function ($$v) {_vm.$set(form, "ssl_card_number", $$v)},expression:"form.ssl_card_number"}}),_c(VTextField,{directives:[{name:"maska",rawName:"v-maska"}],attrs:{"data-maska":"###","outlined":"","append-icon":"mdi-lock","label":"CVV","hide-details":"auto"},model:{value:(form.ssl_cvv2cvc2),callback:function ($$v) {_vm.$set(form, "ssl_cvv2cvc2", $$v)},expression:"form.ssl_cvv2cvc2"}}),_c(VTextField,{directives:[{name:"maska",rawName:"v-maska"}],attrs:{"data-maska":"##/##","outlined":"","label":_vm.t('Expiry Date'),"placeholder":"MM/YY","append-icon":"mdi-calendar","hide-details":"auto"},model:{value:(form.ssl_exp_date),callback:function ($$v) {_vm.$set(form, "ssl_exp_date", $$v)},expression:"form.ssl_exp_date"}}),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"depressed":"","color":"primary","loading":loading},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.t('Proceed'))+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }