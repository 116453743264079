import type { PaymentMethodTypes } from '@/types/payment/methods'

export const paymentMethodCodes: (string | PaymentMethodTypes)[] = [
  'braintree',
  'cardknox',
  'paypal',
  'poynt_terminal',
  'poynt',
  'stripe',
  'bank_transfer',
  'cardknox_pos',
  'converge',
  'tranzila',
  'offline',
  'heartland',
  'cash_on_delivery',
]

export const isOffline = (val: string): boolean => val.endsWith('Offline')
